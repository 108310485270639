//
// @file MoreAbout.tsx
// @author Stephen Francis
// @author David Hammond
// @date 21 Jan 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as React from "react";
import { Link, } from "react-router-dom";
import Home from "../main/Home";
import { Footer, ButtonGroup, Button, } from "../main/Components";


interface Props {
}

export default class PrivacyPolicy extends React.Component<Props> {

  render() {
    return (
      <div>
        <h2>Privacy Policy</h2>
        <p><b>Your Data</b> : This app does not currently capture any information about you, your
          financial situation, or any property you are buying or considering buying. The app sends
          some <i>anonymous</i> data about your usage of it back to our servers - when you open the
          app, and which tasks require notification alerts.
        </p>
        <p>There are two reasons we capture and store this data: to send you alerts (if you choose
          to receive them) about tasks you need to perform; and to help us understand how people
          use this app, to help us improve it.
        </p>
        <p>We do not divulge your data to third-parties unless legal obliged to do so.
        </p>
        <p><b>Device Permissions</b> : This app does not request any permissions from your mobile
          device that it does not need. The only permission we request is to allow notification
          alerts - for tasks that need to be performed. You can choose to deny this permission
          without affecting the rest of the app's functionality.
        </p>
        <Footer>
          <ButtonGroup toSide="left">
            <Link to="/home" className="button">
              <span className="button-text">Back</span>
            </Link>
          </ButtonGroup>
        </Footer>
      </div>
    );
  }
}
