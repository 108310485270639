//
// @file TaskTemplate.ts
// @author Stephen Francis
// @author David Hammond
// @date 9 Feb 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import { v4 as Uuidv4 } from "uuid";
import Action from "./Action";
import Activator from "./Activator";
import Alert from "./Alert";
import AlertTemplate from "./AlertTemplate";
import ProjectTemplate from "./ProjectTemplate";
import Ref from "./Ref";
import Referenced from "./Referenced";
import Task from "./Task";

export default class TaskTemplate implements Referenced {
  private actions: Array<Action>;
  private activations: Array<Activator>;
  private alert_template: AlertTemplate;
  private category_id: string;
  private description: string;
  private id: string;
  private project_template: Ref<ProjectTemplate>;
  private title: string;

  constructor() {
    this.actions = new Array<Action>();
    this.activations = new Array<Activator>();
  }

  public getActions(): Array<Action> {
    return this.actions;
  }

  public getActivations(): Array<Activator> {
    return this.activations;
  }

  public getAlertTemplate(): AlertTemplate {
    return this.alert_template;
  }

  public getCategoryId(): string {
    return this.category_id;
  }

  public getClassName(): string {
    return "TaskTemplate";
  }

  public getDescription(): string {
    return this.description;
  }

  public getId(): string {
    return this.id;
  }

  public getProjectTemplate(): ProjectTemplate {
    return this.project_template.getValue();
  }

  public getTitle(): string {
    return this.title;
  }

  // Returns a new Task instance linked to this task template.
  public getNewTask(): Task {
    let task = new Task();
    task.setTaskTemplate(Ref.resolved(this));
    task.setCreationDate(new Date());
    task.setStatus("OPEN");
    task.setId(Uuidv4());
    const alert_template = this.getAlertTemplate();
    if (alert_template) {
      const alert = new Alert();
      alert.setId(Uuidv4());
      alert.setAlertAt(alert_template.getAlertDateTime(new Date()));
      alert.setAlertText(alert_template.getAlertText());
      alert.setAlertTemplate(Ref.resolved(alert_template));
      task.setAlert(alert);
    }
    return task;
  }

  public isTransientProperty(property_name: string): boolean {
    return property_name === "project_template";
  }

  public setActions(actions: Array<Action>) {
    this.actions = new Array<Action>();
    actions.forEach((a) => {
      a.setTaskTemplate(this);
      this.actions.push(a);
    });
  }

  public setActivations(activations: Array<Activator>) {
    this.activations = activations;
  }

  public setAlertTemplate(alert_template: AlertTemplate) {
    this.alert_template = alert_template;
  }

  public setCategoryId(category_id: string) {
    this.category_id = category_id;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public setId(id: string) {
    this.id = id;
  }

  public setProjectTemplate(project_template: Ref<ProjectTemplate>) {
    this.project_template = project_template;
  }

  public setTitle(title: string) {
    this.title = title;
  }
}
