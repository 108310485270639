//
// @file Onboarding01.tsx
// @author Stephen Francis
// @author David Hammond
// @date 23 May 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as React from "react";
import { Link, } from "react-router-dom";
import RootLog from "loglevel";
import { ButtonGroup, Footer, } from "../main/Components";

const Log = RootLog.getLogger("Onboarding01");


interface Props {
}

interface State {
}

export default class Onboarding01 extends React.Component<Props, State> {

  render() {
    return (
      <div>
        <p>Welcome to <b>door-key</b>!</p>

        <p>The simple and smart way to</p>
        <ul>
          <li><b>track your property purchase</b> and</li>
          <li><b>be guided</b> through the whole process, from start to finish!</li>
        </ul>

        <Footer>
          <ButtonGroup toSide="right" width="100%">
            <Link className="button" to="/onboarding02">
              <span className="button-text">Next</span>
            </Link>
          </ButtonGroup>
        </Footer>
      </div>
    );
  }
}
