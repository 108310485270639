//
// @file RestartProject.tsx
// @author Stephen Francis
// @author David Hammond
// @date 21 Jan 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as React from "react";
import { Link, } from "react-router-dom";
import RootLog from "loglevel";
import Home from "../main/Home";
import { Footer, ButtonGroup, Button, } from "../main/Components";
import sendGoogleAnalyticsEvent from "../../firebase/sendGoogleAnalyticsEvent";

const Log = RootLog.getLogger("RestartProject");

interface Props {
  startProject: Function;
}

export default class RestartProject extends React.Component<Props> {

  handleClick() {
    Log.debug("RestartProject.handleClick");
    this.props.startProject();
    sendGoogleAnalyticsEvent("restart_project", "restart");
  }


  render() {
    return (
      <div>
        <h2>Restart your Project</h2>
        <p>WARNING!</p>
        <p>This will reset your project back to the beginning, and delete all your data!</p>
        <p>Click &quot;Confirm&quot; below to proceed...</p>
        <Footer>
          <ButtonGroup toSide="left">
            <Link to="/home" className="button">
              <span className="button-text">Back</span>
            </Link>
          </ButtonGroup>
          <ButtonGroup toSide="right">
            <Link onClick={this.handleClick.bind(this)} to="/home" className="button">
              <span className="button-text">Confirm</span>
            </Link>
          </ButtonGroup>
        </Footer>
      </div>
    );
  }
}
