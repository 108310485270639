//
// @file App.tsx
// @author Stephen Francis
// @author David Hammond
// @date 21 Jan 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as React from "react";
import * as ReactDOM from "react-dom";
import { HashRouter, Link, Redirect, Route, Switch } from "react-router-dom";
import _ from "underscore";
import RootLog from "loglevel";
import BurgerMenu from "./BurgerMenu";
import MainProjectTemplate from "../../../docs/templates/main/_persist.json";
import Profile from "../../firebase/Profile";
import Project from "../../model/project/Project";
import ProjectStore from "../../firebase/ProjectStore";
import sendGoogleAnalyticsEvent from "../../firebase/sendGoogleAnalyticsEvent";

import AboutThisApp from "../ancillary/AboutThisApp";
// import GiveFeedback from "../ancillary/GiveFeedback";
import Home from "./Home";
import Onboarding from "../ancillary/Onboarding";
import Onboarding01 from "../ancillary/Onboarding01";
import Onboarding02 from "../ancillary/Onboarding02";
import Onboarding03 from "../ancillary/Onboarding03";
import Onboarding04 from "../ancillary/Onboarding04";
import Onboarding05 from "../ancillary/Onboarding05";
import PrivacyPolicy from "../ancillary/PrivacyPolicy";
// import PromptToSignIn from "../ancillary/PromptToSignIn";
import RestartProject from "../ancillary/RestartProject";
import TaskDisplay from "../project/TaskDisplay";

declare const window: any;
// declare const cxApi: any;
// declare const WebpackMode: string;

RootLog.setLevel("debug");
const Log = RootLog.getLogger("App");

ProjectStore.addProjectTemplate(MainProjectTemplate);

interface Props {}

interface State {
  profile: Profile;
  project: Project;
  ready: boolean;
}

// Top level app component
class App extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const profile = this.loadProfile();
    const project = this.loadProject(profile);

    // Set the initial state
    this.state = {
      ready: true,
      project,
      profile,
    };
    console.log(`end of constructor, after setState()`);
  }

  private loadProfile(): Profile {
    const profile = Profile.getProfile();
    Log.debug(`loadProfile() got profile: ${JSON.stringify(profile, null, 2)}`);
    return profile;
  }

  private loadProject(profile: Profile): Project {
    // Log.debug(`starting loadProject() MainProjectTemplate: ${Object.keys(MainProjectTemplate)}`);
    let project: Project = null;
    try {
      const project_id = profile.getProjectId();
      if (project_id) {
        project = ProjectStore.load(project_id);
        Log.debug(`loadProject() got project...`);
      } else {
        Log.debug(`loadProject() got no project`);
      }
      this.setProject(project);
    } catch (err) {
      Log.error(err);
      sendGoogleAnalyticsEvent("loadProject", "error", err);
    }
    return project;
  }

  private onboardingRedirect(): React.ReactElement<any> {
    if (this.state.project) {
      return null;
    }
    let onboarding_start_page = "/onboarding01";
    // if (cxApi && cxApi.chooseVariation() && WebpackMode !== "development") {
    //   onboarding_start_page = "/onboarding";
    // }
    return <Redirect from="/home" to={onboarding_start_page} />;
  }

  public render(): React.ReactElement<any> {
    console.log(`render() ${Object.keys(this.state)}, ${this.state.ready}`);
    if (this.state.ready) {
      return this.renderReady();
    }
    return <div>Loading...</div>;
  }

  private renderReady(): React.ReactElement<any> {
    Log.debug(`renderReady() ${!!this.state.project}`);
    return (
      <HashRouter>
        <div>
          <div className="header-strip" id="outer-container">
            <h1>
              <Link to="/home">
                <img src="favicon-32x32.png" />
                door-key
              </Link>
            </h1>
            <BurgerMenu profile={this.state.profile} />
          </div>
          <main id="page-wrap">
            <Route
              path="/:route"
              render={(props) => {
                sendGoogleAnalyticsEvent(props.match.params.route, "routeTo");
                return null;
              }}
            />
            <Route
              path="/home"
              render={() => {
                // if (!!this.state.project) {
                //   this.state.profile.checkNotificationPermissionIfUnknown();
                // }
                return null;
              }}
            />
            <Switch>
              {this.onboardingRedirect()}

              <Redirect from="/" to="/home" exact />

              <Route
                path="/about"
                render={() => (
                  <AboutThisApp
                    profile={this.state.profile}
                    project={this.state.project}
                  />
                )}
              />

              {/* <Route
                path="/feedback"
                render={() => <GiveFeedback profile={this.state.profile} />}
              /> */}

              <Route
                path="/home"
                render={() => <Home project={this.state.project} />}
              />

              <Route path="/onboarding01" render={() => <Onboarding01 />} />

              <Route path="/onboarding02" render={() => <Onboarding02 />} />

              <Route path="/onboarding03" render={() => <Onboarding03 />} />

              <Route path="/onboarding04" render={() => <Onboarding04 />} />

              <Route
                path="/onboarding05"
                render={() => (
                  <Onboarding05 startProject={this.startProject.bind(this)} />
                )}
              />

              <Route
                path="/onboarding"
                exact
                render={() => (
                  <Onboarding startProject={this.startProject.bind(this)} />
                )}
              />

              <Route path="/privacy" render={() => <PrivacyPolicy />} />

              {/* <Route
                path="/prompt"
                render={() => <PromptToSignIn profile={this.state.profile} />}
              /> */}

              <Route
                path="/restart"
                render={() => (
                  <RestartProject startProject={this.startProject.bind(this)} />
                )}
              />

              <Route
                path="/task/:task_id"
                render={({ match }) => (
                  <TaskDisplay
                    profile={this.state.profile}
                    project={this.state.project}
                    saveProject={this.saveProject.bind(this)}
                    task={this.state.project.getTask(match.params.task_id)}
                  />
                )}
              />

              <Route
                render={({ location }) => {
                  sendGoogleAnalyticsEvent(
                    "navigationError",
                    "error",
                    location.pathname
                  );
                  return <p>Navigation error</p>;
                }}
              />
            </Switch>
          </main>
        </div>
      </HashRouter>
    );
  }

  public saveProject(): void {
    try {
      Log.info(`saveProject() obj: ${this.state.project.getId()}`);
      ProjectStore.save(this.state.project);
    } catch (err2) {
      Log.error(`error in saveProject() end of sync seq: ${err2}`);
      sendGoogleAnalyticsEvent("saveProject", "error", err2);
    }
  }

  private setProject(project: Project): void {
    Log.debug(
      `setProject() got project: ${project ? project.getId() : "null"}`
    );
    window["project"] = project;
  }

  public startProject(): void {
    const project = ProjectStore.create("main");
    this.state.profile.setProjectId(project.getId());
    this.setProject(project);
    this.setState({
      project,
    });
    Log.debug(`loadProject(): ${project.getId()}`);
  }
}

// Render app
ReactDOM.render(<App />, window.document.getElementById("app"));
