//
// @file Profile.ts
// @author Stephen Francis
// @author David Hammond
// @date 30 Mar 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import RootLog from "loglevel";
import ProfileStore from "./ProfileStore";

const Log = RootLog.getLogger("Profile");
const ProfileIdLocalStore = new ProfileStore();

declare const window: any;

export interface PersistedProfile {
  created_date: Date;
  has_been_prompted: boolean;
  project_id?: string;
  starts?: Array<Date>;
}

export default class Profile {
  private persisted: PersistedProfile;
  private id: string;
  private is_new: boolean;

  constructor(persisted: PersistedProfile, is_new: boolean) {
    Log.debug(`new Profile() starting`);
    this.persisted = persisted;
    this.is_new = is_new;
    this.addProfileStart();
    Log.debug(`new Profile() done addProfileStart()`);
  }

  // public addFeedbackItem(rating: number, message: string): void {
  //   const feedback_obj = new FeedbackItem({
  //     message: message,
  //     rating: rating,
  //   });
  //   this.feedback.push(feedback_obj);
  //   this.save();
  // }

  private addProfileStart() {
    this.persisted.starts.push(new Date());
    this.save();
  }

  // private deflateFeedback(feedback_array: Array<FeedbackItem>): Array<any> {
  //   const new_array: Array<any> = [];
  //   (feedback_array || []).forEach((feedback_item: FeedbackItem) => {
  //     new_array.push(feedback_item.getConstrob());
  //   });
  //   return new_array;
  // }

  public getCreatedDate(): Date {
    return this.persisted.created_date;
  }

  public getDisplayName(): string {
    return "unavailable";
  }

  public getEmailAddress(): string {
    return "unavailable";
  }

  // public getExistingFeedback(): Array<FeedbackItem> {
  //   return this.feedback;
  // }

  public getId(): string {
    return this.id;
  }

  static getNewProfileConstrob(): PersistedProfile {
    return {
      created_date: new Date(),
      has_been_prompted: false,
      starts: [],
    };
  }

  public static getProfile(): Profile {
    const existing_profile: PersistedProfile = ProfileIdLocalStore.get();
    if (existing_profile) {
      return new Profile(existing_profile, false);
    }
    const new_profile = new Profile(this.getNewProfileConstrob(), true);
    new_profile.save();
    return new_profile;
  }

  public getProjectId(): string {
    return this.persisted.project_id;
  }

  public hasBeenPrompted(): boolean {
    return this.persisted.has_been_prompted;
  }

  // private inflateFeedback(feedback_array: Array<any>): Array<FeedbackItem> {
  //   const new_array: Array<FeedbackItem> = [];
  //   (feedback_array || []).forEach(function (feedback_item: any) {
  //     new_array.push(new FeedbackItem(feedback_item));
  //   });
  //   return new_array;
  // }

  public isAuthenticatedUser(): boolean {
    return true;
  }

  public isNewProfile(): boolean {
    return this.is_new;
  }

  public save(): void {
    ProfileIdLocalStore.set(this.persisted);
  }

  public setProjectId(project_id: string): void {
    this.persisted.project_id = project_id;
    this.save();
  }

  public setHasBeenPrompted(has_been_prompted: boolean): void {
    this.persisted.has_been_prompted = has_been_prompted;
    this.save();
  }
}
