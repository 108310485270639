//
// @file ProjectTemplate.ts
// @author Stephen Francis
// @author David Hammond
// @date 9 Feb 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as _ from "underscore";
import Project from "./Project";
import Ref from "./Ref";
import Referenced from "./Referenced";
import Task from "./Task";
import TaskTemplate from "./TaskTemplate";


export default class ProjectTemplate implements Referenced {
  private id: string;
  private initial_task_templates: Array<Ref<TaskTemplate>>;
  private task_templates: Array<TaskTemplate>;
  private version: number;


  constructor() {
    this.task_templates = [];
  }


  public getClassName(): string {
    return "ProjectTemplate";
  }


  public getId() : string {
    return this.id;
  }


  public getInitialTasks(): Array<Task> {
    // Map the array of task templates to an array of tasks.
    return this.initial_task_templates.map(tt => tt.getValue().getNewTask());
  }


  public getFinalTaskTemplate(): TaskTemplate {
    return this.task_templates.find(t => t.getId() === "finished");
  }


  public getTaskTemplates(): Array<TaskTemplate> {
    return this.task_templates;
  }


  public getVersion(): number {
    return this.version;
  }


  // Given a project, loop through the task templates to see if any need to be
  // activated. Not all task templates are tested for possible activation.
  public getNewTasks(project: Project, last_completed_task: Task): Array<Task> {
    const open_task_templates = project.getTemplatesOfOpenTasks();
    return this.task_templates
      .filter(tt => _.includes(open_task_templates, tt) === false)
      .reduce((new_tasks, tt) => {
        if (tt.getActivations()
          .some(a => a.shouldBeActivated(project, tt, last_completed_task))) {
          new_tasks.push(tt.getNewTask());
        }
        return new_tasks;
      }, []);
  }


  public setId(id: string) {
    this.id = id;
  }


  public setInitialTaskTemplates(initial_task_templates: Array<Ref<TaskTemplate>>) {
    this.initial_task_templates = initial_task_templates;
  }


  public setTaskTemplates(task_templates: Array<TaskTemplate>) {
    task_templates.forEach(tt => {
      tt.setProjectTemplate(Ref.resolved(this));
      this.task_templates.push(tt);
    });
  }


  public setVersion(version: number) {
    this.version = version;
  }

}
