//
// @file ProjectStore.ts
// @author Stephen Francis
// @author David Hammond
// @date 17 Apr 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

// import "firebase/firestore";
import { v4 as Uuidv4 } from "uuid";
import RootLog from "loglevel";
// import FirebaseApp from "./FirebaseApp";
import Project from "../model/project/Project";
import ProjectPersistor from "../model/project/ProjectPersistor";
import ProjectTemplate from "../model/project/ProjectTemplate";
import Ref from "../model/project/Ref";

const Log = RootLog.getLogger("ProjectStore");

const PREFIX = "~DOORKEY~";

class ProjectStore {
  private project_templates: any;

  constructor() {
    this.project_templates = {};
  }

  public addProjectTemplate(project_template_json: any): void {
    const project_template = ProjectPersistor.parse(
      JSON.stringify(project_template_json)
    ) as ProjectTemplate;
    this.project_templates[project_template.getId()] = project_template;
    Log.debug(
      `addProjectTemplate() added template: ${project_template.getId()}`
    );
  }

  public create(project_template_id: string): Project {
    const project_template = this.project_templates[project_template_id];
    if (!project_template) {
      throw new Error(`project template ${project_template_id} not recognized`);
    }
    const project: Project = new Project();
    project.setId(Uuidv4());
    project.setProjectTemplate(Ref.resolved(project_template));
    project.start();
    this.save(project); // don't wait for save
    return project;
  }

  public load(project_id: string): Project {
    Log.debug(`load() starting with given project_id: ${project_id}`);
    return ProjectPersistor.parse(
      window.localStorage.getItem(PREFIX + project_id)
    ) as Project;
  }

  public save(project: Project): void {
    window.localStorage.setItem(
      PREFIX + project.getId(),
      ProjectPersistor.stringify(project)
    );
  }
}

export default new ProjectStore();
