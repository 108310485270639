//
// @file Task.ts
// @author Stephen Francis
// @author David Hammond
// @date 9 Feb 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import Action from "./Action";
import Alert from "./Alert";
import Project from "./Project";
import Ref from "./Ref";
import Referenced from "./Referenced";
import TaskTemplate from "./TaskTemplate";


export default class Task implements Referenced {
  private alert: Alert;
  private category_id: string;
  private completed_action: Ref<Action>;
  private completion_date: Date;
  private creation_date: Date;
  private id: string;
  private project: Project;
  private status: string;
  private task_template: Ref<TaskTemplate>;
  private title: string;


  constructor() {
    this.task_template = Ref.null_reference();
  }


  public getAlert(): Alert {
    return this.alert;
  }


  public getCategoryId(): string {
    if (this.category_id) {
      return this.category_id;
    } else if (this.hasTaskTemplate()) {
      return this.getTaskTemplate().getCategoryId();
    } else {
      return null;
    }
  }


  public getClassName(): string {
    return "Task";
  }


  public getCompletedAction(): Action {
    return this.isCompleted() ? this.completed_action.getValue() : null;
  }


  public getCompletionDate(): Date {
    return this.completion_date;
  }


  public getCreationDate(): Date {
    return this.creation_date;
  }


  public getDescription(): string {
    if (this.hasTaskTemplate()) {
      return this.getTaskTemplate().getDescription();
    } else {
      return null;
    }
  }


  public getId(): string {
    return this.id;
  }


  public getStatus(): string {
    return this.status;
  }


  public getTaskTemplate(): TaskTemplate {
    return this.task_template.getValue();
  }


  public getTitle(): string {
    if (this.title) {
      return this.title;
    } else if (this.hasTaskTemplate()) {
      return this.getTaskTemplate().getTitle();
    } else {
      return null;
    }
  }


  public hasTaskTemplate(): boolean {
    return this.task_template.isResolved();
  }


  public getActions(): Array<Action> {
    if (this.hasTaskTemplate()) {
      return this.getTaskTemplate().getActions();
    } else {
      // TODO do something else
      return [];
    }
  }


  // Performing an action on a task completes the task.
  public complete(action: Action): Array<Task> {
    this.completed_action = Ref.resolved(action);
    this.completion_date = new Date();
    this.status = "COMPLETED";
    return this.project.taskCompleted(this);
  }


  public isOpen(): boolean {
    return this.status === "OPEN";
  }


  public isClosed(): boolean {
    return !this.isOpen();
  }


  public isCompleted(): boolean {
    return this.status === "COMPLETED";
  }


  public isTransientProperty(property_name: string): boolean {
    return property_name === "project";
  }


  public setAlert(alert: Alert) {
    this.alert = alert;
  }


  public setCategoryId(str: string) {
    this.category_id = str;
  }


  public setCompletedAction(completed_action: Ref<Action>) {
    this.completed_action = completed_action;
  }


  public setCompletionDate(completion_date: Date) {
    this.completion_date = completion_date;
  }


  public setCreationDate(creation_date: Date) {
    this.creation_date = creation_date;
  }


  public setId(id: string) {
    this.id = id;
  }


  public setProject(project: Project) {
    this.project = project;
  }


  public setStatus(status: string) {
    this.status = status;
  }


  public setTaskTemplate(task_template: Ref<TaskTemplate>) {
    this.task_template = task_template;
  }


  public setTitle(str: string) {
    this.title = str;
  }

}
