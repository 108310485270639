//
// @file IdLocalStore.ts
// @author Stephen Francis
// @author David Hammond
// @date 24 Apr 2018
// @copyright Copyright © 2018. All Rights Reserved.
//
import { v4 as Uuidv4 } from "uuid";

const store_id: string = "~DOORKEY~profile";

export default class ProfileStore {
  private local_storage: any;

  constructor() {
    try {
      if (window) {
        this.setLocalStorage(window.localStorage);
      }
    } catch (e) {} // silently ignore no window object or invalid localStorage object
  }

  public generate(): string {
    this.set(Uuidv4());
    return this.get();
  }

  public get(): any {
    if (!this.local_storage) {
      throw new Error(`localStorage object not defined`);
    }
    const data = this.local_storage.getItem(store_id);
    return JSON.parse(data);
  }

  public set(data: any): void {
    if (!this.local_storage) {
      throw new Error(`localStorage object not defined`);
    }
    this.local_storage.setItem(store_id, JSON.stringify(data));
  }

  setLocalStorage(localStorage: any): void {
    if (!localStorage || typeof window.localStorage.setItem !== "function") {
      throw new Error(`invalid localStorage object`);
    }
    this.local_storage = localStorage;
  }
}
