//
// @file Ref.js
// @author Stephen Francis
// @author David Hammond
// @date 9 Feb 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as RootLog from "loglevel";
import Referenced from "./Referenced";

const Log = RootLog.getLogger("Ref");
// Log.setLevel("debug");


export default class Ref<T extends Referenced> {
  private id: string;
  private type: string;
  private value: T;


  static null_reference<T extends Referenced>() : Ref<T> {
    return new Ref<T>(null, null, null);
  }


  static unresolved<T extends Referenced>(type: string, id: string) : Ref<T> {
    return new Ref<T>(type, id, null);
  }


  static resolved<T extends Referenced>(value: T) : Ref<T> {
    return new Ref<T>(value.getClassName(), value.getId(), value);
  }


  constructor(type: string, id: string, value: T) {
    this.type = type;
    this.id = id;
    this.value = value;
  }


  public getClassName(): string {
    return "Ref";
  }


  public getId(): string {
    return this.id;
  }


  public getType(): string {
    return this.type;
  }


  public getValue(): T {
    return this.value;
  }


  public isResolved() {
    return this.value !== null;
  }


  public isTransientProperty(property_name: string): boolean {
    return property_name === "value";
  }


  public resolve(value: T) {
    Log.debug(`resolve(${value} ${typeof value.getClassName} ${typeof value.getId})`);
    if (value.getClassName() == this.type &&
        value.getId() == this.id) {
      this.value = value;
    }
  }

}
