//
// @file Onboarding.tsx
// @author Stephen Francis
// @author David Hammond
// @date 31 Jan 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as React from "react";
import { Redirect, } from "react-router-dom";
import RootLog from "loglevel";
import { ButtonGroup, Button, Footer, } from "../main/Components";
import { ProgressMeter, } from "../project/Components";

const Log = RootLog.getLogger("Onboarding");


interface Props {
  startProject: Function;
}

interface State {
  finished: boolean;
}

export default class Onboarding extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    Log.debug(`Onboarding.constructor`);
    this.state = {
      finished: false,
    } as State;
  }


  componentWillUnmount() {
    Log.debug(`Onboarding.componentWillUnmount()`);
  }


  onClick() {
    const that = this;
    Log.debug(`Onboarding.onClick()`);
    this.props.startProject();
    that.setState({
      finished: true,
    });
  }


  render() {
    Log.debug(`Onboarding.render() finished?: ${this.state.finished}`);
    if (this.state.finished) {
      return (<Redirect to="/home" />);
    }
    return this.renderContent();
  }


  renderContent() {
    return (
      <div>
        <p>Welcome to <b>door-key</b>!</p>

        <p>The simple and smart way to</p>
        <ul>
          <li><b>track your property purchase</b> and</li>
          <li><b>be guided</b> through the whole process!</li>
        </ul>

        <p>We have broken the whole property-buying process into a set
          of bite-size <b>tasks</b> so you only have to worry about
          what is relevant to you at each stage.</p>

        <div>
          <a className="button task-button">
            <div style={{
              display: "flex",
              flexDirection: "row",
            }}>
              <div style={{ flex: "0 0 30px", paddingRight: "10px", }}>
                <img src="building_30x30.png" />
              </div>
              <div style={{ flex: "1 2 auto", }} className="task-button-text">
                Hunt for and Shortlist Properties
              </div>
              <div style={{ flex: "0 0 20px", paddingLeft: "10px", }}>
                <div className="task-status-circle" >
                  <img style={{ verticalAlign: "top", }} src="checkmark_30x30.png" />
                </div>
              </div>
            </div>
          </a>
        </div>

        <p style={{
          display: "inline-block",
          width: "50%",
        }}>
          And we give you a simple view of your <b>progress</b> all the way
          through, to see how things are going.
        </p>

        <div style={{
          border: "1px solid #ddd",
          borderRadius: "10px",
          padding: "10px",
          marginTop: "20px",
          width: "fit-content",
          float: "right",
        }}>
          <h3 style={{ margin: "0 0 10px 0", }}>Progress</h3>
          <ProgressMeter
            progress={75}
            outerSize={120}
          />
        </div>

        <p>You can create an account and sign-in at any time, to be able to access your project
          on multiple devices and to receive notifications of due tasks.
        </p>

        <Footer>
          <ButtonGroup toSide="right" width="100%">
            <Button onClick={this.onClick.bind(this)}>
              <span className="button-text">Let's get started!</span>
            </Button>
          </ButtonGroup>
        </Footer>
      </div>
    );
  }
}

/*
The trick of using both onClick and to attributes in a <Link> element doesn't
work here as it does in the RestartProject component, presumably because the
to="/home" re-routing is performed before the execution of onClick(), and
App.renderReady() contains a <Redirect> back to here if no project exists,
which it doesn't until onClick() here is executed.
*/
