//
// @file AlertTemplate.ts
// @author Stephen Francis
// @author David Hammond
// @date 23 Feb 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import Referenced from "./Referenced";


export default class AlertTemplate implements Referenced {
  private days_from_activation_to_alert: number;
  private id: string;
  private alert_text: string;


  public getClassName(): string {
    return "AlertTemplate";
  }


  public getId(): string {
    return this.id;
  }


  public getAlertDateTime(activated_at: Date): Date {
    let time: number = activated_at.getTime();
    time += (this.days_from_activation_to_alert * 24 * 60 * 60 * 1000);
    let date = new Date(time);
    date.setHours(8);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }


  public getAlertText(): string {
    return this.alert_text;
  }


  public setDaysFromActivationToAlert(days: number): void {
    this.days_from_activation_to_alert = days;
  }


  public setId(id: string) {
    this.id = id;
  }


  public setAlertText(str): void {
    this.alert_text = str;
  }

}
