//
// @file Project.ts
// @author Stephen Francis
// @author David Hammond
// @date 9 Feb 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as RootLog from "loglevel";
import * as _ from "underscore";
import Action from "./Action";
import Category from "./Category";
import PercentComplete from "./PercentComplete";
import ProjectTemplate from "./ProjectTemplate";
import Ref from "./Ref";
import Task from "./Task";
import TaskTemplate from "./TaskTemplate";


const Log = RootLog.getLogger("Project");

export default class Project {
  private id: string;
  private project_template: Ref<ProjectTemplate>;
  private started_date: Date;
  private tasks: Array<Task>;
  private completed_actions: Object;
  private cache_percent_complete: Map<string, number>;


  constructor() {
    this.project_template = Ref.null_reference();
    this.tasks = [];
    this.completed_actions = {};
    this.cache_percent_complete = new Map<string, number>();
  }


  public getClassName(): string {
    return "Project";
  }


  public getId(): string {
    return this.id;
  }


  public getProjectTemplate(): ProjectTemplate {
    return this.project_template.getValue();
  }


  public getStartedDate(): Date {
    return this.started_date;
  }


  public getTask(task_id: string): Task {
    return _.find(this.getTasks(), (task: Task) => { return task.getId() === task_id; });
  }


  public getTasks(): Array<Task> {
    return this.tasks;
  }


  // Records that the project has started. If the project has an associated
  // project template then initial template tasks are added as tasks to the
  // project.
  public start() {
    if (this.project_template.isResolved()) {
      this.getProjectTemplate().getInitialTasks().forEach(this.addTask, this);
    }
    this.started_date = new Date();
  }


  // Restarts the project using the current project template if one exists.
  public restart() {
    this.tasks = [];
    this.completed_actions = {};
    this.cache_percent_complete.clear();
    this.start();
  }


  public taskCompleted(task: Task): Array<Task> {
    // If the completed task has a task template, then we need to check
    // with the project template to see if any additional task templates
    // have been activated. If they have, then we add the corresponding new
    // tasks to the project.
    this.cache_percent_complete.clear(); // reset % complete cache
    this.setCompletedActionOfTask(task);
    if (task.hasTaskTemplate()) {
      const newly_created_tasks = task.getTaskTemplate().getProjectTemplate()
        .getNewTasks(this, task);
      newly_created_tasks.forEach(this.addTask, this);
      return newly_created_tasks;
    }
  }


  public filterTasks(filter): Array<Task> {
    return this.getTasks().filter(filter);
  }


  public getPercentComplete(category: Category) {
    if (this.cache_percent_complete.size === 0) {
      this.cache_percent_complete = PercentComplete(this);
    }
    return this.cache_percent_complete.get(category.getId());
  }


  public addTask(task: Task) {
    task.setProject(this);
    this.tasks.push(task);
    if (task.isCompleted()) {
      // Remember this action has been completed for convenience
      this.setCompletedActionOfTask(task);
    }
  }


  // Returns an array of all the task templates that the current open tasks in
  // this project are linked to.
  public getTemplatesOfOpenTasks(): Array<TaskTemplate> {
    return this.tasks.reduce((list, t) => {
      if (t.hasTaskTemplate() && t.isOpen()) {
        list.push(t.getTaskTemplate());
      }
      return list;
    }, []);
  }


  public hasProjectTemplate(): boolean {
    return this.project_template.isResolved();
  }


  // Return true if there is a task in the project completed with the specified
  // action.
  public isActionCompleted(action: Action): boolean {
    return this.completed_actions[action.getId()] === true;
  }


  public isTransientProperty(property_name: string): boolean {
    return property_name === "completed_actions" ||
           property_name === "cache_percent_complete";
  }


  private setCompletedActionOfTask(task: Task): void {
    const action = task.getCompletedAction();
    if (action) {
      this.completed_actions[action.getId()] = true;
    }
  }


  public setId(id: string) {
    this.id = id;
  }


  public setProjectTemplate(project_template: Ref<ProjectTemplate>) {
    this.project_template = project_template;
  }


  public setTasks(tasks: Array<Task>) {
    tasks.forEach(this.addTask, this);
  }


  public setStartedDate(started_date: Date) {
    this.started_date = started_date;
  }

}
