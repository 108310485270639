//
// @file Components.tsx
// @author Stephen Francis
// @author David Hammond
// @date 31 Jan 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as React from "react";
import * as _ from "underscore";
import RootLog from "loglevel";

const Log = RootLog.getLogger("main.Components");

export class Footer extends React.Component {
  render(): React.ReactElement<any> {
    return (
      <div className="footer-strip">
        <div style={{ padding: "5px 0 0 5px", }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}


interface ButtonGroupProps {
  children: React.ReactNode;
  width?: string;
  toSide?: string;
  spaceBetween?: boolean;
  togglable?: boolean;
  selectedKey?: string;
}

export class ButtonGroup extends React.Component<ButtonGroupProps> {
  render(): React.ReactElement<any> {
    const style: any = {
      display: "inline",
    };
    var className;
    if (this.props.spaceBetween === false) {
      // style.paddingRight = "0";
      style.display = "inline-block";
      style.margin = "0 5px 5px 0"; // pad bottom and right sides if buttons ARE grouped
      style.float = this.props.toSide;
    } else if (this.props.toSide) {
      className = "float-children-" + this.props.toSide;
    }
    // if (this.props.width) {
    //   style.width = this.props.width;
    // }
    return (
      <div style={style} className={className}>
        {this.renderChildren()}
      </div>
    );
  }

  renderChildren() {
    const that = this;
    const run_children_togther = (this.props.spaceBetween === false);
    const total = React.Children.count(this.props.children);
    let i = 0;
    return React.Children.map(this.props.children, function (child: any) {
      const override_props: any = {};
      i += 1;
      if (run_children_togther) {
        override_props.groupStart = (i === 1);
        override_props.groupEnd = (i === total);
      }
      if (that.props.togglable) { // only override a button's is_selected prop if group is togglable
        override_props.isSelected = (child.key === that.props.selectedKey);
      }
      if (that.props.toSide) {
        override_props.orientation = that.props.toSide;
      }
      Log.trace("inside renderChildren map, child " + i + ", key: " + child.key
        + ", child type: " + child.type
        + ", override_props: " + JSON.stringify(override_props));
      return React.cloneElement(child, override_props);
    });
  }
}


interface ButtonProps {
  children: React.ReactNode;
  onClick(): void;
  isSelected?: boolean;
  orientation?: string;
  groupStart?: boolean;
  groupEnd?: boolean;
}

export class Button extends React.Component<ButtonProps> {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e): void {
    e.preventDefault();
    Log.debug("Button.handleClick()");
    this.props.onClick();
  }


  getBorderRadius(): string {
    let top_left = "5px ";
    let top_right = "5px ";
    let bottom_left = "5px ";
    let bottom_right = "5px ";
    if (this.props.orientation === "left") {
      top_right = "15px ";
      bottom_left = "15px ";
    } else if (this.props.orientation === "right") {
      top_left = "15px ";
      bottom_right = "15px ";
    }
    if (this.props.groupEnd === false) {
      top_right = "0 ";
      bottom_right = "0 ";
    }
    if (this.props.groupStart === false) {
      top_left = "0 ";
      bottom_left = "0 ";
    }
    return top_left + top_right + bottom_right + bottom_left;
  }


  getBorderWidth(): string {
    return "1px " + ((this.props.groupEnd === false) ? "0 " : "1px ")
      + "1px " + ((this.props.groupStart === false) ? "0 " : "1px");
  }


  render(): React.ReactElement<any> {
    const style: any = { // minimum rules to override 'a.button' CSS selector...
      backgroundColor: (this.props.isSelected ? "#aaa" : "#ddd"),
      borderRadius: this.getBorderRadius(),
      borderWidth: this.getBorderWidth(),
      margin: 0,
    };
    if (typeof this.props.groupStart !== "boolean") {
      style.margin = "0 5px 5px 0"; // pad bottom and right sides if buttons NOT grouped
    }
    Log.trace("button " + JSON.stringify(_.filter(this.props, function (value, key) {
      return ([ "isSelected", "groupStart", "groupEnd", ].indexOf(key) > -1);
    })));
    return (
      <a onClick={this.handleClick} style={style} className="button">
        {this.props.children}
      </a>
    );
  }
}
