//
// @file Action.ts
// @author Stephen Francis
// @author David Hammond
// @date 9 Feb 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import Referenced from "./Referenced";
import TaskTemplate from "./TaskTemplate";


export default class Action implements Referenced {
  private id: string;
  private label: string;
  private task_template: TaskTemplate;


  public getClassName(): string {
    return "Action";
  }


  public getId(): string {
    return this.id;
  }


  public getLabel(): string {
    return this.label;
  }


  public getTaskTemplate() {
    return this.task_template
  }


  public setId(id: string) {
    this.id = id;
  }


  public setLabel(label: string) {
    this.label = label;
  }


  public setTaskTemplate(task_template: TaskTemplate) {
    this.task_template = task_template;
  }


  public isTransientProperty(property_name: string): boolean {
    return property_name === "task_template";
  }

}
