//
// @file FromActions.ts
// @author Stephen Francis
// @author David Hammond
// @date 9 Feb 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import Action from "./Action";
import Activator from "./Activator";
import Project from "./Project";
import Ref from "./Ref";
import Task from "./Task";
import TaskTemplate from "./TaskTemplate";


export default class FromActions implements Activator {
  private actions: Array<Ref<Action>>;


  public getActions(): Array<Ref<Action>> {
    return this.actions;
  }


  public getClassName(): string {
    return "FromActions";
  }


  public getDependencies(): Array<Action> {
    return this.actions.reduce((dependencies, a) => {
      if (a.isResolved())
        dependencies.push(a.getValue());
      return dependencies;
    }, new Array<Action>());
  }


  public setActions(actions: Array<Ref<Action>>) {
    this.actions = actions;
  }


  public shouldBeActivated(project: Project, task_template: TaskTemplate,
                             last_completed_task: Task) : boolean {
    let match_all_actions = true;
    let match_last_action = false;
    let last_action = last_completed_task.getCompletedAction();
    this.actions.forEach(a => {
      match_all_actions = match_all_actions && project.isActionCompleted(a.getValue());
      if (!match_last_action) {
        match_last_action = last_action === a.getValue();
      }
    });
    return match_all_actions && match_last_action;
  }

}
