//
// @file Alert.ts
// @author Stephen Francis
// @author David Hammond
// @date 23 Feb 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import AlertTemplate from "./AlertTemplate";
import Ref from "./Ref";
import Referenced from "./Referenced";


enum Status {
  Created,
  Pending,
  Done,
  Cancelled
}

export default class Alert implements Referenced {
  private id: string;
  private alert_template: Ref<AlertTemplate>;
  private alert_text: string;
  private alert_at: Date;
  private status: Status = Status.Created;


  constructor() {
    this.status = Status.Pending;
  }


  public getAlertAt(): Date {
    return this.alert_at;
  }


  public getAlertTemplate(): AlertTemplate {
    return this.alert_template.getValue();
  }


  public getAlertText(): string {
    return this.alert_text;
  }


  public getClassName(): string {
    return "Alert";
  }


  public getId(): string {
    return this.id;
  }


  public getStatus(): Status {
    return this.status;
  }


  public isPending(): boolean {
    return (this.status === Status.Pending);
  }


  public setAlertAt(date: Date) {
    this.alert_at = date;
  }


  public setAlertTemplate(alert_template: Ref<AlertTemplate>) {
    this.alert_template = alert_template;
  }


  public setAlertText(str: string) {
    this.alert_text = str;
  }


  public setCancelled(): void {
    this.setStatus(Status.Cancelled);
  }


  public setDone(): void {
    this.setStatus(Status.Done);
  }


  private setStatus(status: Status): void {
    if (status === this.status) {
      return; // no-op
    }
    if (status === Status.Created) {
      throw new Error("cannot change status back to Created");
    }
    if (this.status === Status.Done || this.status === Status.Cancelled) {
      throw new Error("cannot change status once set to Done to Cancelled");
    }
    this.status = status;
  }


  public setId(id: string) {
    this.id = id;
  }


  public setPending(): void {
    this.setStatus(Status.Pending);
  }

}
