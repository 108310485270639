//
// @file Components.tsx
// @author Stephen Francis
// @author David Hammond
// @date 31 Jan 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as React from "react";
import { Link, } from "react-router-dom";
import * as RootLog from "loglevel";
import Task from "../../model/project/Task";
import Category from "../../model/project/Category";
import TaskStatus from "../../model/project/TaskStatus";
import { Button, } from "../main/Components";

const Log = RootLog.getLogger("project.Components");

interface TaskButtonProps {
  task: Task;
  onClick(task: Task): void;
}

export class TaskButton extends React.Component<TaskButtonProps> {
  constructor(props) {
    super(props);
  }


  render() {
    const category = Category.get(this.props.task.getCategoryId());
    const template = this.props.task.getTaskTemplate();
    const tmpl_id = template && template.getId();
    const task_id = this.props.task.getId();
    return (
      <Link className="button task-button" to={"task/" + task_id} id={tmpl_id || task_id}>
        <div style={{
          display: "flex",
          flexDirection: "row",
        }}>
          <div style={{ flex: "0 0 30px", paddingRight: "10px", }}>
            <CategoryIcon category={category} />
          </div>
          <div style={{ flex: "1 2 auto", }} className="task-button-text">
            {this.props.task.getTitle()}
          </div>
          <div style={{ flex: "0 0 20px", paddingLeft: "10px", }}>
            <TaskStatusIcon task={this.props.task} />
          </div>
        </div>
      </Link>
    );
  }
}


interface TaskButtonsProps {
  tasks: Array<Task>;
  sortBy?: string;
  onClick(task: Task): void;
}

export class TaskButtons extends React.Component<TaskButtonsProps> {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(task) {
    Log.debug("TaskButtons.handleClick()");
    this.props.onClick(task);
  }

  render() {
    function sortCreatedAsc(a, b) {
      return a.getCreationDate().getTime() - b.getCreationDate().getTime();
    }
    function sortCompletedDesc(a, b) {
      return b.getCompletionDate().getTime() - a.getCompletionDate().getTime();
    }
    var chosen_sort = sortCreatedAsc;
    if (this.props.sortBy === "completion_desc") {
      chosen_sort = sortCompletedDesc;
    }
    const children = this.props.tasks.sort(chosen_sort).map(
      (task, key) => <TaskButton key={key} task={task} onClick={this.handleClick}/>);
    if (children.length === 0) {
      return (
        <div>No tasks</div>
      );
    } else {
      return (
        <div className="block-full-width">
          {children}
        </div>
      );
    }
  }
}


interface CategoryIconProps {
  category: Category;
}

export class CategoryIcon extends React.Component<CategoryIconProps> {
  render() {
    return (
      <img src={this.props.category.getIconFile()} />
    );
  }
}


interface TaskStatusIconProps {
  task: Task;
}

export class TaskStatusIcon extends React.Component<TaskStatusIconProps> {
  render() {
    const is_completed = (this.props.task.getStatus() === "COMPLETED");
    return (
      <div className="task-status-circle" >
        {is_completed && this.renderCompleted()}
        {!is_completed && this.renderOpen()}
      </div>
    );
  }


  renderCompleted() {
    const icon_file = TaskStatus.get(this.props.task.getStatus()).getIconFile();
    return (
      <img style={{ verticalAlign: "top", }} src={icon_file} />
    );
  }


  renderOpen() {
    const alert = this.props.task.getAlert();
    const style = {
      color: "purple",
      lineHeight: "30px",
    };
    let text = "-";
    let due_date = null;
    if (alert) {
      due_date = alert.getAlertAt();
    }
    if (due_date) {
      Log.debug(`due_date? ${due_date}  typeof ${typeof due_date} `);
      let days_to_due = Math.round((due_date.getTime() - Date.now()) / (1000 * 60 * 60 * 24));
      if (days_to_due < 0) {
        days_to_due *= -1;
        style.color = "red";
      }
      text = String(days_to_due);
    }
    return (
      <b style={style}>{text}</b>
    );
  }

}


interface ToggleTaskStatusButtonProps {
  taskStatus: TaskStatus;
  onClick(): void;
}

export class ToggleTaskStatusButton extends React.Component<ToggleTaskStatusButtonProps> {
  render() {
    return (
      <Button
        onClick={this.props.onClick}
        orientation="left"
        isSelected={(this.props.taskStatus.getId() === "COMPLETED")}>
        <img src="checkmark_30x30.png" />
      </Button>
    );
  }
}


interface ProgressMeterProps {
  progress: number;
  outerSize: number;
}

export class ProgressMeter extends React.Component<ProgressMeterProps> {

  getStaticSizes() {
    var out: any = {
      outer_size: this.props.outerSize || 56,
    };
    out.outer_size_str = out.outer_size + "px";
    out.stroke = Math.floor(out.outer_size / 10) + 1;
    out.strokeWidth = out.stroke + "px";
    out.offset_x = Math.ceil(out.stroke / 2);
    out.offset_y = Math.ceil(out.stroke / 2);
    out.radius = Math.ceil(out.outer_size / 2) - out.offset_x;
    out.font_size = Math.floor(out.outer_size / 4) + 2;
    out.font_size_str = out.font_size + "px";
    out.text_offset_x = (Math.floor((out.outer_size - out.font_size) / 2.8) - 2) + "px";
    out.text_offset_y = (Math.floor((out.outer_size - out.font_size) / 2.2) - 0) + "px";
    return out;
  }

  addDynamicSizes(sizes) {
    sizes.endpt_x = Math.round(sizes.offset_x + sizes.radius *
      (1 + Math.sin(this.props.progress * Math.PI / 50)));
    sizes.endpt_y = Math.round(sizes.offset_y + sizes.radius *
      (1 - Math.cos(this.props.progress * Math.PI / 50)));
    sizes.large_arc_flag = (this.props.progress >= 50) ? 1 : 0;
    sizes.trace_arc_expr = "M " + (sizes.offset_x + sizes.radius) + "," + sizes.offset_y
      + " A " + sizes.radius + "," + sizes.radius + " 0 1"
      + " 1 " + (sizes.offset_x + sizes.radius) + "," + (sizes.offset_y);
    sizes.arc_expr = "M " + (sizes.offset_x + sizes.radius) + "," + sizes.offset_y
      + " A " + sizes.radius + "," + sizes.radius + " 0 " + sizes.large_arc_flag
      + " 1 " + sizes.endpt_x + "," + sizes.endpt_y;
  }

  render() {
    var sizes = this.getStaticSizes();
    this.addDynamicSizes(sizes);
    Log.trace("sizes: " + JSON.stringify(sizes));
    return (
      <div style={{
        position: "relative",
        height: sizes.outer_size_str,
        width: sizes.outer_size_str,
      }}>
        <div style={{ position: "absolute", display: "inline-block", left: 0, }}>
          <svg width={sizes.outer_size} height={sizes.outer_size}>
            <circle r={sizes.radius}
              cx={sizes.offset_x + sizes.radius} cy={sizes.offset_y + sizes.radius}
              fill="transparent" stroke="#ddd" strokeWidth={(sizes.stroke - 6) + "px"} />
          </svg>
        </div>
        <div style={{ position: "absolute", display: "inline-block", left: 0, }}>
          <svg width={sizes.outer_size} height={sizes.outer_size}>
            <path d={sizes.arc_expr}
              fill="transparent" stroke="purple" strokeWidth={sizes.strokeWidth} />
          </svg>
        </div>
        <div id="progress_text" style={{
          position: "absolute",
          top: sizes.text_offset_y,
          right: sizes.text_offset_x,
          fontSize: sizes.font_size_str,
          fontWeight: "bold",
        }}>
          {this.props.progress}
          <span style={{ fontSize: "0.9em", color: "#999", marginLeft: "3px", }}>%</span>
        </div>
      </div>
    );
  }
}
