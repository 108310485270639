//
// @file Home.js
// @author Stephen Francis
// @author David Hammond
// @date 21 Jan 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as React from "react";
// import { Link } from "react-router-dom";
import * as _ from "underscore";
import RootLog from "loglevel";
import Category from "../../model/project/Category";
import Project from "../../model/project/Project";
import TaskStatus from "../../model/project/TaskStatus";
// import TaskDisplay from "../project/TaskDisplay";
import { Footer, ButtonGroup, Button } from "./Components";
import {
  TaskButtons,
  ProgressMeter,
  ToggleTaskStatusButton,
  CategoryIcon,
} from "../project/Components";

const Log = RootLog.getLogger("Home");

interface Props {
  project: Project;
}

interface State {
  task_status_filter: TaskStatus;
  category_filter: Category;
}

export default class Home extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      task_status_filter: TaskStatus.get("OPEN"),
      category_filter: Category.get("All"),
    } as State;
  }

  handleDisplayTask(task) {
    Log.debug("Home.handleDisplayTask for task: " + task.getTitle());
  }

  toggleTaskStatusFilter() {
    var new_task_status_filter_id =
      this.state.task_status_filter.getId() === "OPEN" ? "COMPLETED" : "OPEN";
    // Log.debug("task status filter handler: " + task_status.getTitle() + ", " + that);
    Log.debug(
      "Home.handleToggleTaskStatus new id: " + new_task_status_filter_id
    );
    this.setState({
      task_status_filter: TaskStatus.get(new_task_status_filter_id),
    });
  }

  setCategoryFilter(category: Category) {
    Log.debug("Home.render() category filter handler: " + category.getTitle());
    this.setState({
      category_filter: category,
    });
  }

  render() {
    return (
      <div>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <div style={{ flex: "1 1 120px" }}>{this.renderCategory()}</div>
          <div style={{ flex: "0 0 120px" }}>{this.renderProgress()}</div>
        </div>
        <div>{this.renderTasks()}</div>
        {this.renderFooter()}
      </div>
    );
  }

  renderCategory() {
    return (
      <div style={{ paddingTop: "11px" }}>
        <h3 style={{ margin: "0 0 10px 0" }}>
          <div
            style={{
              float: "left",
              marginRight: "10px",
              position: "relative",
              top: "-2px",
            }}
          >
            <CategoryIcon category={this.state.category_filter} />
          </div>
          {this.state.category_filter.getTitle()}
        </h3>
        {this.state.category_filter.getDescription()}
      </div>
    );
  }

  renderProgress() {
    return (
      <div
        style={{
          border: "1px solid #ddd",
          borderRadius: "10px",
          padding: "10px",
          margin: "0 0 0 10px",
          width: "fit-content",
        }}
      >
        <h3 style={{ margin: "0 0 10px 0" }}>Progress</h3>
        <ProgressMeter
          progress={this.props.project.getPercentComplete(
            this.state.category_filter
          )}
          outerSize={120}
        />
      </div>
    );
  }

  renderTasks() {
    const that = this;
    const tasks = this.props.project.filterTasks(function (task) {
      return (
        that.state.task_status_filter.includesTask(task) &&
        that.state.category_filter.includesTask(task)
      );
    });
    const sort_by =
      this.state.task_status_filter.getId() === "COMPLETED"
        ? "completion_desc"
        : "creation_asc";
    return (
      <div style={{ paddingTop: "21px" }}>
        <h3 style={{ margin: "0 0 10px 0", display: "inline-block" }}>
          {this.state.task_status_filter.getTitle()} Tasks
        </h3>
        <h4 style={{ float: "right", fontSize: 16, paddingTop: 10 }}>
          Days to Due
        </h4>
        <TaskButtons
          onClick={this.handleDisplayTask.bind(this)}
          tasks={tasks}
          sortBy={sort_by}
        />
      </div>
    );
  }

  renderFooter() {
    const that = this;
    const category_filter_buttons = _.map(
      ["Money", "Building", "Legal", "All"],
      function (category_id) {
        const category = Category.get(category_id);
        return (
          <Button
            onClick={that.setCategoryFilter.bind(that, category)}
            orientation="right"
            key={category.getId()}
          >
            <CategoryIcon category={category} />
          </Button>
        );
      }
    );
    return (
      <Footer>
        <ButtonGroup toSide="left">
          <ToggleTaskStatusButton
            onClick={this.toggleTaskStatusFilter.bind(this)}
            taskStatus={this.state.task_status_filter}
          />
        </ButtonGroup>
        <ButtonGroup
          toSide="right"
          spaceBetween={false}
          togglable={true}
          selectedKey={this.state.category_filter.getId()}
        >
          {category_filter_buttons}
        </ButtonGroup>
      </Footer>
    );
  }
}
