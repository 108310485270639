//
// @file PercentComplete.ts
// @author Stephen Francis
// @author David Hammond
// @date 13 Mar 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import Category from "./Category";
import Project from "./Project";
import ProjectTemplate from "./ProjectTemplate";
import TaskTemplate from "./TaskTemplate";

// TODO put this somewhere, used in Category.ts as well
interface HasCategory {
  getCategoryId(): string;
}

// Data structure to track a chain of dependent tasks
class Chain {
  private links: Set<TaskTemplate>;
  private head: TaskTemplate;

  constructor(head: TaskTemplate, links?: Set<TaskTemplate>) {
    this.links = links
      ? new Set<TaskTemplate>(links)
      : new Set<TaskTemplate>(links);
    this.setHead(head);
  }

  public getLinks(): Set<TaskTemplate> {
    return this.links;
  }

  public getHead(): TaskTemplate {
    return this.head;
  }

  public hasLink(tt: TaskTemplate): boolean {
    return this.links.has(tt);
  }

  public setHead(head: TaskTemplate) {
    this.head = head;
    this.links.add(head);
  }
}

// Returns an array of the task template dependencies for a specified
// task template.
function getTaskTemplateDependencies(tt: TaskTemplate): Array<TaskTemplate> {
  return tt.getActivations().reduce((dep, activate) => {
    dep.push(
      ...activate.getDependencies().map((action) => action.getTaskTemplate())
    );
    return dep;
  }, new Array<TaskTemplate>());
}

// Returns a set of all the task templates linked to the current open tasks in
// the project.
function getOpenTaskTemplates(project: Project): Set<TaskTemplate> {
  const open = project.filterTasks((t) => t.isOpen() && t.hasTaskTemplate());
  return new Set<TaskTemplate>(open.map((t) => t.getTaskTemplate()));
}

// Returns a set of all the task templates linked to the current closed tasks in
// the project.
function getClosedTaskTemplates(project: Project): Set<TaskTemplate> {
  const open = project.filterTasks((t) => t.isClosed() && t.hasTaskTemplate());
  return new Set<TaskTemplate>(open.map((t) => t.getTaskTemplate()));
}

// Return the number of task / task templates that match the specified category.
function countTemplatesInCategory(
  templates: Array<HasCategory>,
  c: Category
): number {
  return templates.reduce((a, t) => a + (c.includesTask(t) ? 1.0 : 0.0), 0.0);
}

// Returns a set of all posible task templates linked to tasks that could be
// activated in the projects future.
function getAllPossibleOpenTaskTemplates(
  template: ProjectTemplate,
  project: Project,
  openTemplates: Set<TaskTemplate>
): Set<TaskTemplate> {
  const active_chains = new Array<Chain>();
  active_chains.push(new Chain(template.getFinalTaskTemplate()));
  while (active_chains.length > 0) {
    const chain = active_chains.pop();
    const head = chain.getHead();
    const dep = getTaskTemplateDependencies(head);
    dep.forEach((t) => {
      if (!chain.hasLink(t)) {
        if (openTemplates.has(t)) {
          chain.getLinks().forEach((x) => openTemplates.add(x));
        } else {
          if (dep.length === 1) {
            chain.setHead(t);
            active_chains.push(chain);
          } else {
            active_chains.push(new Chain(t, chain.getLinks()));
          }
        }
      }
    });
  }
  return openTemplates;
}

function templateCalculation(
  template: ProjectTemplate,
  project: Project
): Map<string, number> {
  const percent = new Map<string, number>();
  let currentlyOpen = getOpenTaskTemplates(project);
  if (currentlyOpen.size === 0) {
    // If there are no open templates then the project has been completed and
    // therefore percent complete equals 100%.
    Category.getAll().forEach((c) => percent.set(c.getId(), 100.0));
  } else {
    const open = Array.from(
      getAllPossibleOpenTaskTemplates(template, project, currentlyOpen)
    );
    const closed = project.filterTasks(
      (t) => t.isClosed() && t.hasTaskTemplate()
    );
    Category.getAll().forEach((c) => {
      const open_n = countTemplatesInCategory(open, c);
      const closed_n = countTemplatesInCategory(closed, c);
      percent.set(
        c.getId(),
        open_n + closed_n !== 0
          ? Math.round((100.0 * closed_n) / (open_n + closed_n))
          : 0.0
      );
    });
  }
  return percent;
}

function defaultCalculation(project: Project): Map<string, number> {
  // TODO implement this if required.
  // Could be something as simple as the number of outstanding tasks divided by
  // the total number of tasks.
  const percent = new Map<string, number>();
  Category.getAll().forEach((c) => percent.set(c.getId(), 0.0));
  return percent;
}

export default function PercentComplete(project: Project): Map<string, number> {
  return project.hasProjectTemplate()
    ? templateCalculation(project.getProjectTemplate(), project)
    : defaultCalculation(project);
}
