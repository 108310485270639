//
// @file Persist.js
// @author Stephen Francis
// @author David Hammond
// @date 9 Feb 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as RootLog from "loglevel";
import * as _ from "underscore";
import * as _s from "underscore.string";
import Ref from "./Ref";
import Referenced from "./Referenced";

const Log = RootLog.getLogger("Persist");
// Log.setLevel("debug");


// Simple POD class used as a convenient return type to functions that may or
// may not modify an argument.
class Modified {
  public modified: boolean;
  public value: any;

  static yes(value: any): Modified {
    return new Modified(true, value);
  }

  static no(value: any): Modified {
    return new Modified(false, value);
  }

  constructor (modified: boolean, value: any) {
    this.modified = modified;
    this.value =  value;
  }
}


// JSON.stringify replacer interface with additional arguments for the context
// (The object in which the key was found in) and the public persist API.
interface Replacer {
  replace(key: string, value: any, context: any, persist: Persist): Modified;
}


// JSON.parse reviver interface with additional arguments for the context
// (The object in which the key was found in) and the public persist API.
interface Reviver {
  revive(key: string, value: any, context: any, persist: Persist): Modified;
}


// Date serializer.
class DateSerializer implements Replacer, Reviver {
  public replace(key: string, value: any, context: any, persist: Persist): Modified {
      return (_.isDate(context[key])) ?
        Modified.yes({ __type: "Date", date: value }) :
        Modified.no(value);
  }

  public revive(key: string, value: any, context: any, persist: Persist): Modified {
    return (value.__type == "Date") ?
      Modified.yes(new Date(value.date)) :
      Modified.no(value);
  }
}


// Reference reviver.
class ReferenceReviver implements Reviver {
  public revive(key: string, value: any, context: any, persist: Persist): Modified {
    return (value.__type == "Ref") ?
      Modified.yes(persist.getReferenceCache().getReference(value.type, value.id)) :
      Modified.no(value);
  }
}


// Object serializer.
class ObjectSerializer implements Replacer, Reviver {
  public replace(key: string, value: any, context: any, persist: Persist): Modified {
    // If the property is transient then don't include it in the serialized
    // output.
    if (_.isFunction(context.isTransientProperty) &&
        context.isTransientProperty(key)) {
      return Modified.yes(undefined);
    }
    // If the property is a class that has been registered with Persist then
    // add the additional "__type" property so that it can be deserialized
    // correctly.
    if (typeof value.getClassName === "function" && persist.getConstructor(value.getClassName()) !== null) {
      value.__type = value.getClassName();
      return Modified.yes(value);
    }
    return Modified.no(value);
  }

  public revive(key: string, value: any, context: any, persist: Persist): Modified {
    let construct = persist.getConstructor(value.__type);
    if (construct !== null) {
      const obj = new construct();
      _.each(value, (v, k: string) => {
        const setter = "set" + _s.classify(k);
        if (_.isFunction(obj[setter])) {
          obj[setter].call(obj, v);
        } else if (k !== "__type") {
          Log.warn(`Persist.parseClass(<${value.__type}>): no setter found for property ${k}`);
        }
      });
      if (_.isFunction(obj.getId)) {
        persist.getReferenceCache().addObjectReference(<Referenced> obj);
      }
      return Modified.yes(obj);
    } else {
      return Modified.no(value);
    }
  }
}


// Object reference cache to store and resolve object references.
class ReferenceCache {
  private cache: Map<string, Ref<Referenced>>;

  constructor() {
    this.cache = new Map<string, Ref<Referenced>>();
  }

  // Returns a reference to an object within the cache with specified type and
  // id. If the object is not found then a new null reference is returned.
  public getReference(type: string, id: string) : Ref<Referenced> {
    const key = this.key(type, id);
    if (!this.cache.has(key)) {
      this.cache.set(key, Ref.unresolved<Referenced>(type, id));
    }
    return this.cache.get(key);
  }

  // Add an object reference to the cache. If a reference to the specified
  // object already exists in the cache then then reference value is updated.
  public addObjectReference(obj: Referenced) {
    const key = this.key(obj.getClassName(), obj.getId());
    if (this.cache.has(key)) {
      this.cache.get(key).resolve(obj);
    } else {
      this.cache.set(key, Ref.resolved(obj));
    }
  }

  // Returns an array of unresolved references ids.
  public getUnresolvedReferenceIds() : Array<string> {
    const unresolved = new Array<string>();
    this.cache.forEach((value, key) => {
      if (!value.isResolved()) {
        unresolved.push(key);
      }
    });
    return unresolved;
  }

  // Returns the key used to store an object with specified type and id in the
  // cache
  private key(type: string, id: string): string {
    return type + ":" + id;
  }
}


export default class Persist {
  private class_map: Map<string, new (...args:any[]) => any>;
  private cache: ReferenceCache;
  private replacers: Array<Replacer>;
  private revivers: Array<Reviver>;

  constructor() {
    // A map between class name and constructor function
    this.class_map = new Map<string, new (...args:any[]) => any>();
    // A cache for the references
    this.cache = new ReferenceCache();

    const date_serializer = new DateSerializer();
    const object_serializer = new ObjectSerializer();

    // A list of replacers, evaluated in order.
    this.replacers = new Array<Replacer>();
    this.replacers.push(date_serializer);
    this.replacers.push(object_serializer);

    // A list of revivers, evaluated in order.
    this.revivers = new Array<Reviver>();
    this.revivers.push(date_serializer);
    this.revivers.push(new ReferenceReviver());
    this.revivers.push(object_serializer);
  }

  public addClass(construct: new (...args: any[]) => any) {
    // The type name in the serialized class will be constructor.name property.
    const name = (new construct()).getClassName();
    Log.debug(`addClass(${name})`);
    this.class_map.set(name, construct);
  }

  // Returns the constructor function for a registered class, or null if not
  // found.
  public getConstructor(class_name: string): new (...args:any[]) => any {
    return this.class_map.has(class_name) ?
      this.class_map.get(class_name): null;
  }

  // Returns the reference cache.
  public getReferenceCache(): ReferenceCache {
    return this.cache;
  }

  // Convert object to serialized string.
  public stringify(object: any): string {
    const that = this;
    return JSON.stringify(object, function(key: string, value: any) {
      // Loop through replacers
      for (let r in that.replacers) {
        const m = that.replacers[r].replace(key, value, this, that);
        if (m.modified) {
          return m.value;
        }
      }
      return value;
    }, "  " /* for debug printing */);
  }

  // Parse string to deserialized object.
  public parse(string: string): any {
    const that = this;
    return JSON.parse(string, function(key: string, value: any) {
      // Loop through revivers
      for (let r in that.revivers) {
        const m = that.revivers[r].revive(key, value, this, that);
        if (m.modified) {
          return m.value;
        }
      }
      return value;
    });
  }

}
