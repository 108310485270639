//
// @file ProjectPersistor.ts
// @author Stephen Francis
// @author David Hammond
// @date 15 Feb 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import Action from "./Action";
import Alert from "./Alert";
import AlertTemplate from "./AlertTemplate";
import FromActions from "./FromActions";
import Persist from "./Persist";
import Project from "./Project";
import ProjectTemplate from "./ProjectTemplate";
import Ref from "./Ref";
import Task from "./Task";
import TaskTemplate from "./TaskTemplate";


// Create new persistance interface
// Add all the necessary classes
const persist = new Persist();
persist.addClass(Action);
persist.addClass(Alert);
persist.addClass(AlertTemplate);
persist.addClass(FromActions);
persist.addClass(Project);
persist.addClass(ProjectTemplate);
persist.addClass(Ref);
persist.addClass(Task);
persist.addClass(TaskTemplate);

// Expose for everyone else
export default persist;
