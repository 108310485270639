//
// @file Category.js
// @author Stephen Francis
// @author David Hammond
// @date 31 Jan 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as _ from "underscore";

const categories = {};

interface HasCategory {
  getCategoryId(): string;
}

export default class Category {
  id: string;
  title: string;
  description: string;
  icon_file: string;
  icon: string;
  icon_style: Object;
  icon_source: Object;

  constructor(
    id: string,
    title: string,
    description: string,
    icon_file: string,
    icon: string,
    icon_style: Object
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.icon_file = icon_file;
    this.icon = icon;
    this.icon_style = icon_style;
    categories[id] = this;
  }

  public getId(): string {
    return this.id;
  }

  public getTitle(): string {
    return this.title;
  }

  public getDescription(): string {
    return this.description;
  }

  public getIconFile(): string {
    return this.icon_file;
  }

  public getIcon(): string {
    return this.icon;
  }

  public getIconFileNative(): any {
    return this.icon_source;
  }

  public getIconStyle(): Object {
    return this.icon_style;
  }

  public includesTask(task: HasCategory): boolean {
    return task.getCategoryId() === this.id;
  }

  public setIconFileNative(obj: any): void {
    this.icon_source = obj;
  }

  public static get(id: string): Category {
    id = id || "General"; // blank maps to General!!
    if (!categories[id]) {
      throw new Error("unrecognized category: " + id);
    }
    return categories[id];
  }

  public static getAll(): Array<Category> {
    return _.map(categories, (item: Category) => item);
  }
}

new Category(
  "Building",
  "Building",
  "Structural integrity and local risks identified, checked, and resulting actions taken",
  "building_30x30.png",
  "🏠", // &#127968;
  { fontFamily: "Arial" }
);

new Category(
  "Money",
  "Money",
  "Deposit available, mortgage secured if required, any further funds secured, etc",
  "money_30x30.png",
  "£", // &#163;
  { fontFamily: "Arial" }
);

new Category(
  "Legal",
  "Legal",
  "Legal rights and obligations checked, contract drawn up and agreed, etc",
  "legal_30x30.png",
  "⚖", // &#9878;
  { fontFamily: "Segoe UI Symbol" }
);

new Category(
  "General",
  "General", // Used for blank category
  "Other stuff",
  null,
  "G",
  { fontFamily: "Arial" }
);

new Category(
  "Hidden",
  "Hidden", // for mechanism tasks that should not be shown
  "Fake Tasks",
  null,
  "H",
  { fontFamily: "Arial" }
);

new Category(
  "All",
  "All", // for use in filters ONLY
  "All Categories",
  "globe_30x30.png",
  "🌐", // &#127760;
  { fontFamily: "Arial" }
);

Category.get("All").includesTask = function (task: HasCategory): boolean {
  return task.getCategoryId() !== "Hidden";
};
