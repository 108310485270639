import RootLog from "loglevel";

declare const ga: Function;

RootLog.setLevel(process.env.NODE_ENV === "development" ? "debug" : "warn");
const Log = RootLog.getLogger("sendGAEvent");

if (process.env.NODE_ENV === "production") {
  ga("create", "UA-114971438-1", "auto");
  ga("send", "pageview");
}

export default function sendGAEvent(
  event_id: string,
  category: string,
  label?: string,
  value?: number
): void {
  Log.debug(
    `sendGAEvent ${process.env.NODE_ENV} ${event_id} ${category} ${label} ${value}`
  );
  if (process.env.NODE_ENV === "production") {
    ga("send", "event", category, event_id, label, value);
  }
}
