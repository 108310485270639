//
// @file BurgerMenu.tsx
// @author Stephen Francis
// @author David Hammond
// @date 2 Feb 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as React from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import RootLog from "loglevel";
// import Home from "./Home";
// import AboutThisApp from "../ancillary/AboutThisApp";
// import GiveFeedback from "../ancillary/GiveFeedback";
// import ManageMyAccount from "../ancillary/ManageMyAccount";
// import PrivacyPolicy from "../ancillary/PrivacyPolicy";
import Profile from "../../firebase/Profile";
// import RestartProject from "../ancillary/RestartProject";
// import SignInOut from "../ancillary/SignInOut";

const Log = RootLog.getLogger("BurgerMenu");

interface Props {
  profile: Profile;
}

interface State {
  menu_open: boolean;
}

export default class BurgerMenu extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      menu_open: false,
    };
  }

  handleClick(e: Event): void {
    Log.debug(`handleClick()`);
    this.setState({
      menu_open: false,
    });
  }

  render(): React.ReactElement<any> {
    return (
      <Menu
        isOpen={this.state.menu_open}
        right
        outerContainerId="outer-container"
        pageWrapId="page-wrap"
      >
        <div className="block-full-width">
          <Link
            to="/home"
            onClick={this.handleClick.bind(this)}
            className="button"
          >
            <span className="button-text">Home</span>
          </Link>
          {/* <Link
            to="/feedback"
            onClick={this.handleClick.bind(this)}
            className="button"
          >
            <span className="button-text">Give us Feedback!</span>
          </Link> */}
          {/* <Link
            to="/about"
            onClick={this.handleClick.bind(this)}
            className="button"
          >
            <span className="button-text">About this App</span>
          </Link> */}
          <Link
            to="/privacy"
            onClick={this.handleClick.bind(this)}
            className="button"
          >
            <span className="button-text">Privacy Policy</span>
          </Link>
          <Link
            to="/restart"
            onClick={this.handleClick.bind(this)}
            className="button"
          >
            <span className="button-text">Restart your Project</span>
          </Link>
        </div>
        {/* <SignInOut profile={this.props.profile}  /> */}
      </Menu>
    );
  }
}

/*
          <Link to="/" onClick={this.handleClick.bind(this, ManageMyAccount)} className="button">
            Manage your Account</a>
*/
