//
// @file TaskDisplay.tsx
// @author Stephen Francis
// @author David Hammond
// @date 23 Jan 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as React from "react";
import { Link, Redirect } from "react-router-dom";
import RootLog from "loglevel";
import moment from "moment";
import Action from "../../model/project/Action";
import Category from "../../model/project/Category";
import { Footer, ButtonGroup, Button } from "../main/Components";
import { CategoryIcon, TaskStatusIcon } from "./Components";
import Profile from "../../firebase/Profile";
import Project from "../../model/project/Project";
import sendGoogleAnalyticsEvent from "../../firebase/sendGoogleAnalyticsEvent";
import Task from "../../model/project/Task";
import TaskStatus from "../../model/project/TaskStatus";

const Log = RootLog.getLogger("TaskDisplay");

function formatDate(date) {
  return moment(date).format("h:mma on ddd D MMM");
}

interface Props {
  profile: Profile;
  project: Project;
  saveProject: Function;
  task: Task;
}

interface State {
  selecting_action: Action;
  finished: boolean;
}

export default class TaskDisplay extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selecting_action: null,
      finished: false,
    };
    const task_template = this.props.task.getTaskTemplate();
    sendGoogleAnalyticsEvent(
      task_template && task_template.getId(),
      "taskDisplay"
    );
  }

  cancelAlert() {
    // const alert = this.props.task.getAlert();
    // if (alert && alert.isPending()) {
    //   this.props.profile.cancelAlert(alert.getId());
    //   alert.setCancelled();
    // }
  }

  handleAction(action) {
    this.setState({
      selecting_action: action,
    });
  }

  handleConfirm() {
    // const newly_created_tasks: Array<Task> =
    this.props.task.complete(this.state.selecting_action);
    // this.cancelAlert();
    // this.setupAlerts(newly_created_tasks);
    this.props.saveProject();
    this.setState({
      finished: true,
    });
    sendGoogleAnalyticsEvent(
      this.state.selecting_action.getId(),
      "taskComplete"
    );
  }

  handleBack() {
    if (this.state.selecting_action) {
      this.setState({
        selecting_action: null,
      });
    }
  }

  private promptToSignIn(): boolean {
    Log.debug(
      `promptToSignIn() ${
        this.props.project &&
        this.props.project.getPercentComplete(Category.get("All"))
      }, ${this.props.profile.hasBeenPrompted()}`
    );
    return (
      this.props.project &&
      this.props.project.getPercentComplete(Category.get("All")) > 3 &&
      !this.props.profile.hasBeenPrompted()
    );
  }

  render() {
    return (
      <div>
        {this.state.finished && (
          /* this.promptToSignIn() ? (
            <Redirect to="/prompt" />
          ) : */ <Redirect to="/home" />
        )}
        {this.renderCommonHeader()}
        {this.renderActivatedDue()}
        {!this.props.task.isOpen() && this.renderCompletedTaskInfo()}
        {this.props.task.isOpen() &&
          this.state.selecting_action &&
          this.renderConfirmingAction()}
        {!this.state.selecting_action && this.renderDescription()}
        <Footer>
          <ButtonGroup toSide="left">
            <Link className="button" to="/home">
              <span className="button-text">Back</span>
            </Link>
          </ButtonGroup>
          {this.props.task.isOpen() &&
            this.state.selecting_action &&
            this.renderConfirmButton()}
          {this.props.task.isOpen() &&
            !this.state.selecting_action &&
            this.renderActionButtons()}
        </Footer>
      </div>
    );
  }

  renderActionButtons() {
    const that = this;
    const buttons = this.props.task.getActions().map((action) => (
      <Button
        onClick={that.handleAction.bind(that, action)}
        key={action.getId()}
      >
        <span className="button-text">{action.getLabel()}</span>
      </Button>
    ));
    return <ButtonGroup toSide="right">{buttons}</ButtonGroup>;
  }

  renderActivatedDue() {
    return (
      <div style={{ textAlign: "right" }}>
        <p>
          Activated At: <b>{formatDate(this.props.task.getCreationDate())}</b>
        </p>
        {this.props.task.getAlert() && (
          <p>
            Due At: <b>{formatDate(this.props.task.getAlert().getAlertAt())}</b>
          </p>
        )}
      </div>
    );
  }

  renderCommonHeader() {
    const category = Category.get(this.props.task.getCategoryId());
    const task_status = TaskStatus.get(this.props.task.getStatus());
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ flex: "0 0 40px" }}>
          <CategoryIcon category={category} />
        </div>
        <div style={{ flex: "1 2 auto" }}>
          <h2>{this.props.task.getTitle()}</h2>
        </div>
        <div style={{ flex: "0 0 40px" }}>
          <TaskStatusIcon task={this.props.task} />
        </div>
      </div>
    );
  }

  renderCompletedTaskInfo() {
    const action = this.props.task.getCompletedAction();
    return (
      <div style={{ textAlign: "right" }}>
        <p>
          Closed At: <b>{formatDate(this.props.task.getCompletionDate())}</b>
        </p>
        <p>
          Marked As: <b>{(action && action.getLabel()) || "unknown"}</b>
        </p>
      </div>
    );
  }

  renderConfirmButton() {
    return (
      <ButtonGroup toSide="right">
        <Button onClick={this.handleConfirm.bind(this)}>
          <span className="button-text">Confirm</span>
        </Button>
      </ButtonGroup>
    );
  }

  renderConfirmingAction() {
    return (
      <div id="confirm-text" className="highlight-block">
        Please confirm you want to mark this task as &quot;
        {this.state.selecting_action.getLabel()}&quot;?
      </div>
    );
  }

  renderDescription() {
    return (
      <div
        className="task-description"
        dangerouslySetInnerHTML={{ __html: this.props.task.getDescription() }}
      ></div>
    );
  }
}
