//
// @file TaskStatus.js
// @author Stephen Francis
// @author David Hammond
// @date 31 Jan 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import Task from "./Task";

const task_statuses = {};

export default class TaskStatus {
  id: string;
  title: string;
  description: string;
  icon_file: string;
  icon: string;
  icon_style: Object;
  icon_source: Object;

  constructor(id, title, description, icon_file, icon, icon_style) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.icon_file = icon_file;
    this.icon = icon;
    this.icon_style = icon_style;
    task_statuses[id] = this;
  }


  public getId(): string {
    return this.id;
  }


  public getTitle(): string {
    return this.title;
  }


  public getDescription(): string {
    return this.description;
  }


  public getIconFile(): string {
    return this.icon_file;
  }


  public getIconFileNative(): any {
    return this.icon_source;
  }


  public getIcon(): string {
    return this.icon;
  }


  public getIconStyle(): Object {
    return this.icon_style;
  }


  public includesTask(task: Task): boolean {
    return (task.getStatus() === this.id);
  }


  public setIconFileNative(obj: any): void {
    this.icon_source = obj;
  }


  public static get(id: string): TaskStatus {
    if (!task_statuses[id]) {
      throw new Error("unrecognized status: " + id);
    }
    return task_statuses[id];
  };

}


new TaskStatus("OPEN", "Open",
  "Tasks that need to be completed",
  null,
  "✪", // &#10026;
  { fontFamily: "Arial", });

new TaskStatus("COMPLETED", "Closed",
  "Tasks that have been completed and require no futher action",
  "checkmark_30x30.png",
  "✓", // &#10003?
  { fontFamily: "Arial", });

new TaskStatus("ALL", "All", // for use in filters ONLY
  "All Statuses",
  null,
  "🌐", // &#127760;
  { fontFamily: "Arial", });

TaskStatus.get("ALL").includesTask = function (task) {
  return true;
};
