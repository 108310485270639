//
// @file Onboarding03.tsx
// @author Stephen Francis
// @author David Hammond
// @date 23 May 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as React from "react";
import { Link } from "react-router-dom";
import RootLog from "loglevel";
import { ButtonGroup, Footer } from "../main/Components";
import { ProgressMeter } from "../project/Components";

const Log = RootLog.getLogger("Onboarding03");

interface Props {}

interface State {}

export default class Onboarding03 extends React.Component<Props, State> {
  render() {
    return (
      <div>
        <p
          style={{
            display: "inline-block",
            width: "50%",
          }}
        >
          And we give you a simple view of your <b>progress</b> all the way
          through, to see how things are going.
        </p>

        <div
          style={{
            border: "1px solid #ddd",
            borderRadius: "10px",
            padding: "10px",
            marginTop: "20px",
            width: "fit-content",
            float: "right",
          }}
        >
          <h3 style={{ margin: "0 0 10px 0" }}>Progress</h3>
          <ProgressMeter progress={75} outerSize={120} />
        </div>

        <Footer>
          <ButtonGroup toSide="left" width="100%">
            <Link className="button" to="/onboarding02">
              <span className="button-text">Back</span>
            </Link>
          </ButtonGroup>
          <ButtonGroup toSide="right" width="100%">
            <Link className="button" to="/onboarding05">
              <span className="button-text">Next</span>
            </Link>
          </ButtonGroup>
        </Footer>
      </div>
    );
  }
}
