//
// @file Onboarding05.tsx
// @author Stephen Francis
// @author David Hammond
// @date 23 May 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as React from "react";
import { Redirect, } from "react-router-dom";
import RootLog from "loglevel";

const Log = RootLog.getLogger("Onboarding05");

interface Props {
  startProject: Function;
}

interface State {}

export default class Onboarding05 extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.props.startProject();
  }


  render() {
    return (<Redirect to="/home" />);
  }

}
