//
// @file Onboarding04.tsx
// @author Stephen Francis
// @author David Hammond
// @date 23 May 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as React from "react";
import { Link, } from "react-router-dom";
import RootLog from "loglevel";
import { ButtonGroup, Footer, } from "../main/Components";

const Log = RootLog.getLogger("Onboarding04");


interface Props {}

interface State {}

export default class Onboarding04 extends React.Component<Props, State> {

  render() {
    return (
      <div>
        <p>You can sign-in at any time, using a Google, Facebook or
          Twitter account, to be able to access your project on <b>multiple
          devices</b> and to receive <b>notifications</b> of due tasks.
        </p>

        <a className="button" style={{ marginRight: 10, }}>
          <img src="google_30x30.png" />
        </a>
        <a className="button" style={{ marginRight: 10, }}>
          <img src="facebook_30x30.png" />
        </a>
        <a className="button" style={{ marginRight: 10, }}>
          <img src="twitter_30x30.png" />
        </a>

        <Footer>
          <ButtonGroup toSide="left" width="100%">
            <Link className="button" to="/onboarding03">
              <span className="button-text">Back</span>
            </Link>
          </ButtonGroup>
          <ButtonGroup toSide="right" width="100%">
            <Link className="button" to="/onboarding05">
              <span className="button-text">Let's get started!</span>
            </Link>
          </ButtonGroup>
        </Footer>
      </div>
    );
  }
}
