//
// @file Onboarding02.tsx
// @author Stephen Francis
// @author David Hammond
// @date 23 May 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as React from "react";
import { Link, } from "react-router-dom";
import RootLog from "loglevel";
import { ButtonGroup, Footer, } from "../main/Components";

const Log = RootLog.getLogger("Onboarding02");


interface Props {
}

interface State {
}

export default class Onboarding02 extends React.Component<Props, State> {

  render() {
    return (
      <div>
        <p>We have broken the whole property-buying process into a set
          of bite-size <b>tasks</b> so you only have to worry about
          what is relevant to you at each stage.</p>

        <div>
          <a className="button task-button">
            <div style={{
              display: "flex",
              flexDirection: "row",
            }}>
              <div style={{ flex: "0 0 30px", paddingRight: "10px", }}>
                <img src="building_30x30.png" />
              </div>
              <div style={{ flex: "1 2 auto", }} className="task-button-text">
                Hunt for and Shortlist Properties
              </div>
              <div style={{ flex: "0 0 20px", paddingLeft: "10px", }}>
                <div className="task-status-circle" >
                  <img style={{ verticalAlign: "top", }} src="checkmark_30x30.png" />
                </div>
              </div>
            </div>
          </a>
        </div>

        <Footer>
          <ButtonGroup toSide="left" width="100%">
            <Link className="button" to="/onboarding01">
              <span className="button-text">Back</span>
            </Link>
          </ButtonGroup>
          <ButtonGroup toSide="right" width="100%">
            <Link className="button" to="/onboarding03">
              <span className="button-text">Next</span>
            </Link>
          </ButtonGroup>
        </Footer>
      </div>
    );
  }
}
