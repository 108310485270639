//
// @file AboutThisApp.tsx
// @author Stephen Francis
// @author David Hammond
// @date 21 Jan 2018
// @copyright Copyright © 2018. All Rights Reserved.
//

import * as React from "react";
import { Link, } from "react-router-dom";
import { Footer, ButtonGroup, Button, } from "../main/Components";
import Profile from "../../firebase/Profile";
import Project from "../../model/project/Project";


declare const AppVersion: string;
declare const WebpackMode: string;

interface Props {
  profile: Profile;
  project: Project;
}

export default class AboutThisApp extends React.Component<Props> {

  render() {
    return (
      <div>
        <h2>About this App</h2>
        <p><b>door-key</b> has been designed to guide a homebuyer through the process of buying a
          property in England &amp; Wales. It is NOT a substitute for a Conveyancer (a solicitor
          who specializes in property purchases).
        </p>
        <p>It is being developed by MOBdigital, using the principles of Lean Startup.
          We are committed to continuous
          improvement and rapid release of updates. Your feedback is valuable! Please use the
          "Give Us Feedback" feature in the main menu.
        </p>
        <p>We want to know what experience you have with this app, whether it is useful for you,
          and what other features you would like to see in it.
        </p>
        <p>Our Privacy Policy as also available from the main menu.</p>
        <p>Version: <b>{AppVersion}</b></p>
        <p>Profile Id: <b>{this.props.profile ? this.props.profile.getId() : "not logged in"}</b></p>
        <p>Project Id: <b>{this.props.project ? this.props.project.getId() : "no project"}</b></p>
        <p>Webpack Mode: <b>{WebpackMode}</b></p>
        <Footer>
          <ButtonGroup toSide="left">
            <Link className="button" to="home">
              <span className="button-text">Back</span>
            </Link>
          </ButtonGroup>
        </Footer>
      </div>
    );
  }
}
